import { marker } from "@jsverse/transloco-keys-manager/marker";

import { NewInventoryCreationSchema } from "../../inventory/schemas/index";
import { NewLotCreationSchema } from "../../lot/schemas/index";

import { WorkOrderPlantOutputSchema } from ".";

export const GeneralInventoryOutputDynamicAlphaSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		input_type: {
			type: "string",
			title: "Output Type",
			title_translation_key: marker("form_field_label_output_type"),
			widget: "select",
			oneOf: [
				{
					value: "PLANTS",
					enum: ["PLANTS"],
					name: "Plants",
					name_translation_key: marker("word_plants"),
				},
				{
					value: "INVENTORY",
					enum: ["INVENTORY"],
					name: "Inventory",
					name_translation_key: marker("word_inventory"),
				},
			],
		},

		/**
		 * Plant Material Fields
		 */
		...WorkOrderPlantOutputSchema,

		/**
		 * Inventory Fields
		 */
		inventory_category_id: {
			title: "Inventory Category",
			title_translation_key: marker("word_inventory_category"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "inventory_categories",
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		vendor_id: {
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "vendors",
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["VENDOR"] }],
			},
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["vendor_id"],
			oneOf: [
				{
					result_type: "inventory_products",
					queryString: {
						internal_vendor: "true",
						showArchived: "false",
						non_inventory_destruction: "false",
					},
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
			readOnly: false,
		},
		location_id: {
			type: "number",
			title: "Destination Location",
			title_translation_key: marker("form_field_label_destination_location"),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
			readOnly: false,
		},

		new_inventory: {
			...NewInventoryCreationSchema.new_inventory,
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		create_new_inventory: { ...NewInventoryCreationSchema.create_new_inventory },
		inventory_name: { ...NewInventoryCreationSchema.inventory_name },
		inventory_id: {
			...NewInventoryCreationSchema.inventory_id({
				non_zero_inventory: "false",
			}),
		},

		new_lot: {
			...NewLotCreationSchema.new_lot,
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }, { create_new_inventory: [true] }],
			},
		},
		create_new_lot: { ...NewLotCreationSchema.create_new_lot },
		lot_name: { ...NewLotCreationSchema.lot_name },
		lot_id: {
			...NewLotCreationSchema.lot_id({
				input: "false",
				dont_show_expired: "false",
				non_zero_inventory: "false",
			}),
		},
		sku_id: {
			type: "number",
			title: "SKU",
			title_translation_key: marker("word_sku"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "skus",
					text_key: ["sku"],
				},
			],
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			widget: "number",
			width: "50%",
			title_translation_key: marker("word_quantity"),
			visibleIf: {
				inventory_unit_id: ["$ANY$"],
			},
		},
		inventory_unit_id: {
			type: "number",
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			widget: "data-select",
			width: "50%",
			shorter_placeholder: true,
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},

		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		use_existing_lot_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				new_lot: ["Use Existing Lot"],
			},
		},
		create_new_lot_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				new_lot: ["Create New Lot"],
			},
		},
		remaining_plants_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
			visibleIf: {
				remaining_plants: [true],
			},
		},
		plant_selection_existing: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
			visibleIf: {
				plant_selection: ["EXISTING"],
			},
		},
		move_batch_to_destination: {
			type: "boolean",
			widget: "checkbox",
			title: "Move destination batch to new location",
			title_translation_key: marker(
				"form_field_label_move_destination_batch_to_new_location",
			),
			visibleIf: {
				input_type: ["Plant Material"],
			},
		},
		destination_location_id: {
			type: "number",
			title: "Destination Location",
			title_translation_key: marker("form_field_label_destination_location"),
			description:
				"Destination batch will be moved to this location when the work order is closed.",
			description_translation_key: marker(
				"form_field_description_destination_batch_will_be_moved_to_this_location_when_the_work_order_is_closed",
			),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				move_batch_to_destination: [true],
			},
		},
	},
	anyOf: [
		// Use specific number of plants
		{
			required: ["id", "plant_batch_id", "input_type", "plant_count_use"],
		},
		// Create specific number of plants
		{
			required: ["id", "plant_batch_id", "input_type", "plant_count_create"],
		},
		// Use specific plant ids
		{
			required: ["id", "plant_batch_id", "input_type", "plant_ids"],
		},

		// Use remaining existing plants
		{
			required: [
				"id",
				"plant_batch_id",
				"input_type",
				"plant_selection_existing",
				"remaining_plants_selected",
			],
		},

		/** **********************/
		/** INVENTORY REQUIRED **/
		/** **********************/

		/** FOR BATCH */
		// Enter specific quantity of inventory
		{
			required: [
				"id",
				"location_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
			],
		},
	],
};
