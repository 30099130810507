import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { IInventory, IWorkOrderOutput } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { NewInventoryCreationSchema } from "../../inventory/schemas";
import { NewLotCreationSchema } from "../../lot/schemas/index";
import { SetInventoryStatusSchema } from "../schemas/set-inventory-status";
import { onVendorChange } from "../../shared";

@Component({
	selector: "work-order-drying-output",
	templateUrl: "../../form-view.component.html",
	styleUrls: ["../../sidenav.scss"],
})
export class WorkOrderDryingOutputComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		work_order_type_id: number;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							internal_vendor: "true",
							non_inventory_destruction: "false",
						},
					},
				],
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
					},
				],
				hidden: true,
				related_properties: ["inventory_product_id"],
			},
			batch_id: {
				type: "number",
				title: "Destination Batch",
				title_translation_key: marker("form_field_label_destination_batch"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							input: "true",
							dont_show_expired: "true",
						},
					},
				],
				readOnly: false,
			},
			location_id: {
				type: "number",
				title: "Destination Location",
				title_translation_key: marker("form_field_label_destination_location"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "locations",
						queryString: {
							input: "true",
						},
					},
				],
				readOnly: false,
			},

			new_inventory: { ...NewInventoryCreationSchema.new_inventory },
			create_new_inventory: { ...NewInventoryCreationSchema.create_new_inventory },
			inventory_name: { ...NewInventoryCreationSchema.inventory_name },
			inventory_id: {
				...NewInventoryCreationSchema.inventory_id({
					non_zero_inventory: "true",
				}),
			},

			new_lot: {
				...NewLotCreationSchema.new_lot,
				visibleIf: { allOf: [{ create_new_inventory: [true] }] },
			},
			create_new_lot: { ...NewLotCreationSchema.create_new_lot },
			lot_name: { ...NewLotCreationSchema.lot_name },
			lot_id: {
				...NewLotCreationSchema.lot_id({
					input: "false",
					dont_show_expired: "false",
					non_zero_inventory: "false",
				}),
			},

			value: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
				width: "50%",
			},
			inventory_unit_id: {
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
				width: "50%",
			},
			sku_id: {
				type: "number",
				title: "SKU",
				title_translation_key: marker("word_sku"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "skus",
						text_key: ["sku"],
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: [
			"location_id",
			"inventory_product_id",
			"inventory_unit_id",
			"value",
		],
	};

	weight_unit = this._globals.weight_unit;

	formSchema: any = {};

	secondarySchema: any | undefined = undefined;
	secondaryModel: any = {};
	statusSchema: any | undefined = undefined;
	statusModel: any = {};

	// Injected from other component
	private batch_id: number | undefined;
	private work_order_id: number;
	private work_order_type_id: number;
	private duplicate: IWorkOrderOutput | null = null;
	private initialCopy = false;

	private prevInventoryProductId: number | undefined;
	private readonly duplicate_timestamp: boolean = false;
	private readonly output_inventory_status: boolean = false;
	private readonly default_output_inventory_status_id: number;

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		protected readonly _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _workOrderService: WorkOrderService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Record Dried Output";
		this.form_title_translation_key = marker("form_title_record_dried_output");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		const use_work_order_location_output = this._injector.get(
			"use_work_order_location_output",
			false,
		);
		const work_order_location_id = this._injector.get(
			"work_order_location_id",
			null,
		);

		this.duplicate_timestamp = this._injector.get("duplicate_timestamp", false);
		this.output_inventory_status = this._injector.get(
			"output_inventory_status",
			false,
		);
		this.default_output_inventory_status_id = this._injector.get(
			"default_output_inventory_status_id",
			null,
		);

		if (use_work_order_location_output) {
			this.model.location_id = work_order_location_id;
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.work_order_type_id = this._injector.get("work_order_type_id", null);

		this.schema.properties.inventory_product_id.oneOf[0].queryString = {
			// Retain the queryString already in this schema
			...this.schema.properties.inventory_product_id.oneOf[0].queryString,
			output_inventory_products_for_work_order_type_id: this.work_order_type_id,
		};

		this.model.batch_id = this.batch_id;

		const use_existing_inventory_output = this._injector.get(
			"use_existing_inventory_output",
			false,
		);
		const use_existing_lot_output = this._injector.get(
			"use_existing_lot_output",
			false,
		);

		if (use_existing_inventory_output) {
			this.schema.properties.new_inventory.default = "Use Existing Inventory";
			this.model.new_inventory = "Use Existing Inventory";
		}
		if (use_existing_lot_output) {
			this.schema.properties.new_lot.default = "Use Existing Lot";
			this.model.new_lot = "Use Existing Lot";
		}
		if (this.output_inventory_status) {
			this.statusSchema = SetInventoryStatusSchema;
			this.statusModel.status_id = this.default_output_inventory_status_id;
		}

		const duplicate: IWorkOrderOutput | null = this._injector.get(
			"duplicate",
			null,
		);

		this.duplicate = duplicate;
		if (duplicate) {
			this.model = {
				...this.model,
				inventory_product_id: duplicate.inventory.inventory_product_id,
				inventory_unit_id: duplicate.inventory.inventory_unit_id,
				batch_id: duplicate.batch_id,
				location_id: duplicate.location_id,
				inventory_id: use_existing_inventory_output
					? this.getEntityInventoryId(duplicate.inventory)
					: null,
				lot_id: use_existing_lot_output ? duplicate.inventory.lot_id : null,
				vendor_id: duplicate.inventory.vendor_id,
			};
			if (
				duplicate.inventory.measurements &&
				duplicate.inventory.measurements[0]
			) {
				this.model = {
					...this.model,
					value: -1 * duplicate.inventory.measurements[0].value,
					timestamp: duplicate.inventory.measurements[0].timestamp,
				};
			}
			this.batch_id = duplicate.batch_id;
			this.work_order_id = duplicate.work_order_id;

			// clear timestamp for non GMP
			if (
				!this._globals.gmp_enabled &&
				this.schema.properties.timestamp &&
				!this.duplicate_timestamp
			) {
				delete this.model.timestamp;
			}
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}

					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						inventory_product,
					);

					let inventoryUUID = inventory_product.uuid;
					if (this.duplicate) {
						inventoryUUID = this.duplicate.inventory.inventory_product?.uuid;
					}

					if (this.duplicate && !this.initialCopy) {
						this.model.vendor_id = this.duplicate.inventory.vendor_id;
						this.initialCopy = true;
					}

					// append or copy custom fields to sidebar
					this.getCustomFields(
						inventoryUUID,
						this.duplicate ? this.getEntityInventoryId(this.duplicate.inventory) : "",
					)
						.pipe(
							catchError(() => {
								this.secondaryModel = {};
								this.secondarySchema = undefined;
								this._cd.detectChanges();
								return EMPTY;
							}),
						)
						.subscribe((item) => {
							this.formSchema = JSON.parse(JSON.stringify(item));
							if (Object.keys(this.formSchema?.schema?.properties).length) {
								this.secondarySchema = {
									title: this.formSchema.name ?? "Product Fields",
									type: "object",
									properties: {
										...this.formSchema.schema.properties,
									},
									...(this.formSchema.schema.required && {
										required: [...this.formSchema.schema.required],
									}),
								};

								if (this.formSchema?.model?.length > 0) {
									this.secondaryModel = this.formSchema.model[0].field_model;
								}
							} else {
								this.secondarySchema = undefined;
							}
							this._cd.detectChanges();
						});

					this._cd.detectChanges();
				});
		}
		if (model.inventory_id !== this.model.inventory_id) {
			this.schema.properties.batch_id.readOnly = Boolean(model.inventory_id);
			this.schema.properties.location_id.readOnly = Boolean(model.inventory_id);
			if (model.inventory_id) {
				this._itemService
					.fetchItem(`inventory`, `${model.inventory_id}`)
					.pipe(
						takeUntil(this.destroyed$),
						timeout(50000),
						catchError((error) => {
							/* eslint no-console: off */
							console.error(error);
							return EMPTY;
						}),
					)
					.subscribe((inventory) => {
						this.model.batch_id = inventory.batch_id;
						this.model.location_id = inventory.location_id;
						this._cd.detectChanges();
					});
			}
		}
	}

	updateItem(work_order_output: {
		batch_id: number;
		value: number;
		inventory_product_id: boolean;
		inventory_unit_id: number;
		timestamp?: Date;
	}) {
		this.loading$.next(true);
		let input: any = {
			...work_order_output,
			work_order_id: this.work_order_id,
			batch_id: work_order_output.batch_id
				? work_order_output.batch_id
				: this.batch_id,
			timestamp: work_order_output.timestamp ?? new Date(),
			type: "Inventory",
			status_id: this.statusSchema ? this.statusModel.status_id : undefined,
		};

		// Create Custom Field Model
		if (this.secondarySchema) {
			const custom_fields = {
				custom_field_schema_id: this.formSchema.id,
				type: this.formSchema.type,
				jsonModel: this.secondaryModel,
			};
			input = { ...input, custom_fields };
		}

		this._workOrderService
			.addOutputs(input)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	getCustomFields(type, entity_id?): any {
		return this._itemService.fetchItem("custom_field_model", `${type}`, {
			entity_id: entity_id.toString(),
		});
	}

	getEntityInventoryId(inventory: IInventory | undefined) {
		if (!inventory?.measurements || inventory.measurements.length === 0) {
			return undefined;
		}

		return inventory.measurements[0]!.debit_event?.inventory?.id;
	}
}
