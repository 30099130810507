import {
	IWorkOrder,
	IInventory,
	IInventoryProduct,
} from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { TranslocoService } from "@jsverse/transloco";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, Subscription } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderOutputQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ESValidator } from "app/shared/es-validator";
import { dateIsBefore } from "app/shared/time-format";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import { NewInventoryCreationSchema } from "../inventory/schemas";
import { NewLotCreationSchema } from "../lot/schemas";

import {
	GeneralInventorOutputSchema,
	IGeneralInventoryOutput,
	SetInventoryStatusSchema,
} from "./schemas";

@Component({
	selector: "work-order-return-input-gi",
	templateUrl: "wo-output-form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderReturnInputGIComponent
	extends GenericCreateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	request: Subscription;

	formSchema: any = {};

	secondarySchema: any | undefined = undefined;
	secondaryModel: any = {};
	statusSchema: any | undefined = undefined;
	statusModel: any = {};

	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private readonly work_order_id: number;
	private readonly inventory: IInventory;
	private readonly inventory_unit_id: number;
	private readonly inventory_product: IInventoryProduct;
	private readonly minDate: Date;
	private readonly output_inventory_status: boolean = false;
	private readonly default_output_inventory_status_id: number;

	private dateValidatorFailed: boolean;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);

		this.work_order_id = this._injector.get("work_order_id", null);
		this.inventory = this._injector.get("inventory", null);
		this.inventory_unit_id = this._injector.get("inventory_unit_id", null);
		this.inventory_product = this._injector.get("inventory_product", null);
		this.minDate = this._injector.get("minDate", null);
		this.output_inventory_status = this._injector.get(
			"output_inventory_status",
			false,
		);
		this.default_output_inventory_status_id = this._injector.get(
			"default_output_inventory_status_id",
			null,
		);

		this.model.id = this.work_order_id;
		this.model.new_inventory = "Use Existing Inventory";
		this.model.new_lot = "Use Existing Lot";
		this.model.inventory_id = this.inventory.id;
		this.model.inventory_unit_id = this.inventory_unit_id;
		this.model.batch_id = this.inventory.batch_id;
		this.model.lot_id = this.inventory.lot_id;
		this.model.location_id = this.inventory.location_id;

		this.form_title = `Return unused ${this.inventory_product.name} to Inventory`;
		this.form_title_translation_key = marker(
			"form_title_return_unused_input_to_inventory",
		);
		this.form_title_translation_params = {
			inventory_product_name: `${this.inventory_product.name}`,
		};
		this.submit_button = "Return";
		this.submit_button_translation_key = marker("word_return");
		this.submit_icon = "plus";

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				...(this.inventory.batch_id && {
					batch_id: {
						...GeneralInventorOutputSchema.properties.batch_id,
						visibleIf: true,
						readOnly: true,
					},
				}),
				new_inventory: {
					...NewInventoryCreationSchema.new_inventory,
					default: "Use Existing Inventory",
				},
				location_id: {
					...GeneralInventorOutputSchema.properties.location_id,
					visibleIf: {
						allOf: [{ create_new_inventory: [true] }],
					},
					default: this.inventory.location_id,
				},
				create_new_inventory: {
					...NewInventoryCreationSchema.create_new_inventory,
				},
				inventory_name: { ...NewInventoryCreationSchema.inventory_name },
				inventory_id: {
					...NewInventoryCreationSchema.inventory_id({
						non_zero_inventory: "false",
					}),
					default: this.inventory.id,
					readOnly: true,
				},
				lot_id: {
					...NewLotCreationSchema.lot_id({
						input: "false",
						dont_show_expired: "false",
						non_zero_inventory: "false",
					}),
					default: this.inventory.lot_id,
					readOnly: true,
				},
				inventory_unit_id: {
					...GeneralInventorOutputSchema.properties.inventory_unit_id,
					visibleIf: true,
					readOnly: true,
				},
				quantity: {
					...GeneralInventorOutputSchema.properties.quantity,
				},
				timestamp: {
					...GeneralInventorOutputSchema.properties.timestamp,
				},
			},
			anyOf: [
				/** **********************/
				/** INVENTORY REQUIRED **/
				/** **********************/
				// Enter specific quantity of inventory
				{
					required: ["id", "inventory_unit_id", "quantity"],
				},
			],
		};
		delete this.schema.properties.lot_id.visibleIf;

		// append or copy custom fields to sidebar
		this.getCustomFields(this.inventory_product.uuid, this.inventory.id)
			.pipe(
				catchError(() => {
					this.secondaryModel = {};
					this.secondarySchema = undefined;
					this._cd.detectChanges();
					return EMPTY;
				}),
			)
			.subscribe((item) => {
				this.formSchema = JSON.parse(JSON.stringify(item));
				if (Object.keys(this.formSchema?.schema?.properties).length) {
					for (const key in this.formSchema.schema.properties) {
						if (this.formSchema.schema.properties[key]) {
							this.formSchema.schema.properties[key] = {
								...this.formSchema.schema.properties[key],
								readOnly: true,
							};
						}
					}
					this.secondarySchema = {
						title: this.formSchema.name ?? "Product Fields",
						type: "object",
						properties: {
							...this.formSchema.schema.properties,
						},
					};

					if (this.formSchema?.model?.length > 0) {
						this.secondaryModel = this.formSchema.model[0].field_model;
					}
				} else {
					this.secondarySchema = undefined;
				}
				this._cd.detectChanges();
			});
		if (this.output_inventory_status) {
			this.statusSchema = SetInventoryStatusSchema;
			this.statusModel.status_id = this.default_output_inventory_status_id;
		}
	}

	valid(val) {
		this.valid$.next(val);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled && this.schema.properties.timestamp) {
			delete this.schema.properties.timestamp;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(_model) {}

	getCustomFields(type, entity_id?): any {
		return this._itemService.fetchItem("custom_field_model", `${type}`, {
			entity_id: entity_id.toString(),
		});
	}

	createItem(work_order_output: IGeneralInventoryOutput) {
		// Verify form has quantity
		if (
			work_order_output.inventory_product_id &&
			(!work_order_output.quantity || work_order_output.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_positive_quantity",
				),
			);
			return;
		}
		this.loading$.next(true);

		let update: any = {
			batch_id: work_order_output.batch_id,
			type: "Inventory",
			inventory_product_id: this.inventory.inventory_product_id,
			inventory_unit_id: this.inventory_unit_id,
			inventory_id: work_order_output.inventory_id,
			create_new_inventory: work_order_output.create_new_inventory,
			inventory_name: work_order_output.inventory_name,
			lot_id: work_order_output.lot_id,
			location_id:
				work_order_output.location_id ?? this.inventory.location_id ?? undefined,
			value: work_order_output.quantity,
			timestamp: work_order_output.timestamp,
			status_id: this.statusSchema ? this.statusModel.status_id : undefined,
			sku_id: this.inventory.sku?.id,
			vendor_id: this.inventory.vendor_id,
			is_return: true,
		};

		// Create Custom Field Model
		if (this.secondarySchema) {
			const custom_fields = {
				custom_field_schema_id: this.formSchema.id, // custom_field_schema_id
				type: this.formSchema.type,
				jsonModel: this.secondaryModel,
			};
			update = {
				...update,
				custom_fields,
			};
		}

		this._itemService
			.add(
				`work_order/${work_order_output.id}/outputs`,
				update,
				WorkOrderOutputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
